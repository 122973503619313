



























import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import QuestionsLayout from '@/layouts/question/QuestionsLayout.vue';
import HomeworkQuestionFormTemplate from '@/components/homework/HomeworkQuestionFormTemplate.vue';
import { CourseStores } from '@/store/course';
import { HomeworkStores } from '@/store/homeworks';
import { DropdownItem } from '@/models/dropdown';
import { OneOrMultipleAnswers, NumberChoices, BasicYesOrNo, QuestionTypeNames, VideoFileSizeLimit } from '@/utils/constants';
import { GeneralMCQBody, UpdateHomeworkQuestionForAxios, UpdateHomeworkQuestions } from '@/models/question_banks';
import { QuestionStores } from '@/store/questions';
import { HomeworkQuestionDetailItemResponse } from '@/models/questions';
import { uploadAttachment } from '@/utils/attachments';
@Component({
    components: { Page, QuestionsLayout, HomeworkQuestionFormTemplate },
})
export default class EditHomeworkQuestions extends Vue {

    // Stores
    courseListStore = CourseStores.list;
    homeworkListStore = HomeworkStores.list;
    homeworkStateStore = HomeworkStores.state;
    questionDetailStore = QuestionStores.detail;

    // Dropdowns
    currentCourseData: DropdownItem[] = [];
    currentHomeworkData: DropdownItem[] = [];
    answerOptionList = OneOrMultipleAnswers;
    answerDisplayList = NumberChoices;
    standardInstructionList = BasicYesOrNo;

    // UI State
    currentFormTemplate: UpdateHomeworkQuestions | null = null;

    get title() {
        return `Edit Homework Question`;
    }

    get courses() {
        return this.currentCourseData;
    }
    
    get homeworks() {
        return this.currentHomeworkData;
    }

    get answerOptions() {
        return this.answerOptionList;
    }

    get answerDisplays() {
        return this.answerDisplayList;
    }

    get standardInstructions() {
        return this.standardInstructionList;
    }

    get showTemplate() {
        return this.courses.length > 0 && this.homeworks.length > 0;
    }

    mounted() {
        // this.courseListStore.retrieveCoursesWithParams({});
        this.courseListStore.retrieveAllSiteCoursesWithParams({});
        this.questionDetailStore.retrieveHomeworkQuestionById(Number(this.$route.params.id));
    }

    // API Functions
    getHomeworkList(courseId: number) {
        this.homeworkListStore.retrieveHomeworks({ courseid: courseId });
    }

    async updateHomeworkQuestion(data: UpdateHomeworkQuestions) {
        // Format data to match API
        // Fetch subjectId formId from courses
        const courseIndex = this.courseListStore.response!.objects.findIndex((course) => {
            return data.courseId === course.id;
        });

        if (courseIndex > -1) {
            // General Section
            let input: Partial<UpdateHomeworkQuestionForAxios> = {
                id: Number(this.$route.params.id),
                subjectId: this.courseListStore.response!.objects[courseIndex].subject.id,
                formId: this.courseListStore.response!.objects[courseIndex].form.id,
                courseId: data.courseId,
                homeworkId: data.homeworkId,
                availability: data.availability ? 1 : 0,
                qType: data.questionType,
                name: data.questionName,
                questionText: data.questionText,
                defaultMark: data.defaultMark,
                generalFeedback: data.generalFeedback,
                imageUrl: this.questionDetailStore.fetchedQuestionResponse?.imageUrl,
                videoUrl: this.questionDetailStore.fetchedQuestionResponse?.videoUrl,
                videoThumbnail: this.questionDetailStore.fetchedQuestionResponse?.videoThumbnail,
            };

            // If questionAsset is a video type
            if (data.questionAsset && 
                typeof data.questionAsset !== 'string' &&
                data.questionAsset.originalFile && 
                data.questionAsset.originalFile.type.includes(`video/`) &&
                data.questionAsset.thumbnail
            ) {
                const thumbnailPromise = uploadAttachment(
                    data.questionAsset.thumbnail,
                    data.questionAsset.thumbnail?.name.split(`.`)[0],
                    `thumbnail`
                );
                const videoPromise = uploadAttachment(
                    data.questionAsset.originalFile,
                    data.questionAsset.originalFile.name,
                    `video`,
                    VideoFileSizeLimit.questions
                );
                const promises = await Promise.all([thumbnailPromise, videoPromise]);
                input.videoThumbnail = promises[0];
                input.videoUrl = promises[1];
                input.imageUrl = '';
            }

            if (data.questionAsset && 
                typeof data.questionAsset !== 'string' &&
                data.questionAsset.originalFile && 
                data.questionAsset.originalFile.type.includes(`image/`)
            ) {
                const image = await uploadAttachment(
                    data.questionAsset.originalFile,
                    data.questionAsset.originalFile?.name,
                    `thumbnail`
                );
                input.imageUrl = image;
                input.videoThumbnail = '';
                input.videoUrl = '';
            }

            if (
                data.questionAsset && 
                typeof data.questionAsset !== 'string' &&
                !data.questionAsset.thumbnail &&
                !data.questionAsset.originalFile
            ) { 
                input.imageUrl = '';
                input.videoUrl = '';
                input.videoThumbnail = '';
            }
            
            // TODO: Set approved to be dynamic
            // Mcq
            if ('hasMultipleAnswers' in data) {
                input.single = data.hasMultipleAnswers ? 0 : 1; // 1 means true, 0 means false
                input.shuffleAnswers = data.areChoicesShuffled ? 1 : 0
                input.answerNumbering = data.choiceNumberType;
                input.approved = 0;
                input.responseRequired = 0;

                // Format Choices to match  the API
                let answers: string[] = [];
                let fraction: number[] = [];
                let feedback: string[] = [];

                let currentValidAnswers = 0;
                data.choices.forEach((item) => {
                    if (item.isAnswerValid) {
                        currentValidAnswers++;
                    }
                });

                data.choices.forEach((item) => {
                    answers.push(item.choiceText);
                    fraction.push(item.isAnswerValid ? 1/currentValidAnswers : 0);
                    feedback.push(item.feedbackText);
                });

                input.answer = answers;
                input.fraction = fraction;
                input.feedback = feedback;
            } else {
                // Essay
                input.single = 1; // 1 means true, 0 means false
                input.approved = 0;
                input.shuffleAnswers = 0;
                input.answerNumbering = `abc`; // Filler
                input.responseRequired = data.requireText === '1' ? 1 : 0;
                input.answer = [
                    data.answerText
                ];
                input.fraction = [1];
                input.feedback = [''];
            }

            this.questionDetailStore.updateHomeworkQuestionById(input as UpdateHomeworkQuestionForAxios);
        }
    }

    // Parsing Functions
    @Watch(`courseListStore.response`)
    updateCourses() {
        if (this.courseListStore.response) {
            this.currentCourseData = [];
            this.courseListStore.response.objects.forEach((data) => {
                this.currentCourseData.push({
                    text: data.courseName,
                    value: data.id.toString(),
                    uniqueKey: `data_${data.courseName}`
                });
            });
        }
    }

    @Watch(`homeworkListStore.response`)
    updateHomeworks() {
        if (this.homeworkListStore.response) {
            this.currentHomeworkData = [];
            for (let index = 0; index < this.homeworkListStore.response.objects.length; index++) {
                this.currentHomeworkData.push({
                    text: this.homeworkListStore.response.objects[index].name,
                    value: this.homeworkListStore.response.objects[index].id.toString(),
                    uniqueKey: `data_${this.homeworkListStore.response.objects[index].name}`
                });
            }
        }
    }

    @Watch(`questionDetailStore.fetchedQuestionResponse`)
    updateHomeworkQuestionData() {
        if (this.questionDetailStore.fetchedQuestionResponse) {
            this.getHomeworkList((this.questionDetailStore.fetchedQuestionResponse as HomeworkQuestionDetailItemResponse).courseId);
            switch (this.questionDetailStore.fetchedQuestionResponse.qType) {
                case QuestionTypeNames.mcq : {
                    this.currentFormTemplate = {
                        id: Number(this.$route.params.id),
                        availability: this.questionDetailStore.fetchedQuestionResponse.availability === 1 ? true : false,
                        questionType: this.questionDetailStore.fetchedQuestionResponse.qType,
                        courseId: (this.questionDetailStore.fetchedQuestionResponse as HomeworkQuestionDetailItemResponse ).courseId,
                        homeworkId: (this.questionDetailStore.fetchedQuestionResponse as HomeworkQuestionDetailItemResponse ).homeworkId,
                        questionName: this.questionDetailStore.fetchedQuestionResponse.name,
                        questionText: this.questionDetailStore.fetchedQuestionResponse.questionText,
                        defaultMark: this.questionDetailStore.fetchedQuestionResponse.defaultMark,
                        generalFeedback: this.questionDetailStore.fetchedQuestionResponse.generalFeedback,
                        areChoicesShuffled: this.questionDetailStore.fetchedQuestionResponse.shuffleAnswers === 1 ? true : false,
                        hasMultipleAnswers: this.questionDetailStore.fetchedQuestionResponse.single === 1 ? false : true,
                        choiceNumberType: this.questionDetailStore.fetchedQuestionResponse.answerNumbering,
                        showStandardInstructions: true,
                        choices: [],
                        questionAsset: this.questionDetailStore.fetchedQuestionResponse.videoThumbnail ? this.questionDetailStore.fetchedQuestionResponse.videoUrl : this.questionDetailStore.fetchedQuestionResponse.imageUrl ? this.questionDetailStore.fetchedQuestionResponse.imageUrl : ``,
                    };
                    // Repopulate
                    const anyOptions: GeneralMCQBody[] = [];
                    // we know that the lengths are the same
                    for (let index = 0; index < this.questionDetailStore.fetchedQuestionResponse.answer.length; index++) {
                        const item: GeneralMCQBody = {
                            id: index,
                            choiceText: this.questionDetailStore.fetchedQuestionResponse.answer[index],
                            feedbackText: this.questionDetailStore.fetchedQuestionResponse!.feedback[index],
                            isAnswerValid: this.questionDetailStore.fetchedQuestionResponse!.fraction[index] > 0 ? true : false,
                        };
                        anyOptions.push(item);
                    }
                    this.currentFormTemplate.choices = anyOptions;
                    break;
                }
                case QuestionTypeNames.essay: {
                    // Repopulate
                    this.currentFormTemplate = {
                        id: Number(this.$route.params.id),
                        availability: this.questionDetailStore.fetchedQuestionResponse.availability === 1 ? true : false,
                        questionType: this.questionDetailStore.fetchedQuestionResponse.qType,
                        courseId: (this.questionDetailStore.fetchedQuestionResponse as HomeworkQuestionDetailItemResponse ).courseId,
                        homeworkId: (this.questionDetailStore.fetchedQuestionResponse as HomeworkQuestionDetailItemResponse ).homeworkId,
                        questionName: this.questionDetailStore.fetchedQuestionResponse.name,
                        questionText: this.questionDetailStore.fetchedQuestionResponse.questionText,
                        defaultMark: this.questionDetailStore.fetchedQuestionResponse.defaultMark,
                        generalFeedback: this.questionDetailStore.fetchedQuestionResponse.generalFeedback,
                        answerText: this.questionDetailStore.fetchedQuestionResponse.answer[0],
                        requireText: this.questionDetailStore.fetchedQuestionResponse.responseRequired.toString(),
                        questionAsset: this.questionDetailStore.fetchedQuestionResponse.videoThumbnail ? this.questionDetailStore.fetchedQuestionResponse.videoUrl : this.questionDetailStore.fetchedQuestionResponse.imageUrl ? this.questionDetailStore.fetchedQuestionResponse.imageUrl : ``,
                    };
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    @Watch(`questionDetailStore.response`)
    updateHomeworkStates() {
        if (this.questionDetailStore.response) {
            this.homeworkStateStore.updateHomeworkIsModified(true);
            this.redirectToHomeworkManagement();
        }
    }

    // Routing Functions
    redirectToHomeworkManagement() {
        this.$router.push({
            path: `/homeworks`
        });
    }

}
